import { Injectable, WritableSignal, inject, signal } from '@angular/core';
import { map, Subject } from 'rxjs';
import { StyleTheme } from '@shared/models/theme.model';
import { MenuItem } from '@shared/models/menuItem.model';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { toSignal } from '@angular/core/rxjs-interop';

export type Device = 'mobile' | 'tablet' | 'desktop';
type ViewMode = 'onTable' | 'onForm';

@Injectable({
  providedIn: 'root',
})
export class AppService {
  device = new DeviceAppService();
  sidebar = new SidebarService();
  theme = new ThemeService();
  alerts = new AlertService();

  appTitle = signal<string>('');

  AbmViewMode = signal<ViewMode>('onTable');
  loading = signal<boolean>(false);

  autoOpenForm = signal<boolean>(false);

  _formCheckbox = new Subject<boolean>();
  _check = new Subject<void>();

  get FormCheckbox() {
    return this._formCheckbox.asObservable();
  }

  get Check() {
    return this._check.asObservable();
  }

  reemplazarEspacios(data: string) {
    return data.replace(/\s+/g, '_');
  }

  getElementName<T, K extends keyof T>(obj: T, key: K): T[K] {
    return obj[key];
  }
}

class ThemeService {
  storedTheme = localStorage.getItem('theme');
  currentTheme: WritableSignal<StyleTheme> = signal<StyleTheme>('light');

  getTheme() {
    if (this.storedTheme) {
      this.currentTheme.set(this.storedTheme as StyleTheme);
      document.documentElement.setAttribute('data-bs-theme', this.storedTheme);
    } else {
      this.currentTheme.set(this.getPreferredTheme());
      document.documentElement.setAttribute(
        'data-bs-theme',
        this.getPreferredTheme()
      );
    }
  }

  getPreferredTheme(): StyleTheme {
    if (this.storedTheme) {
      return this.storedTheme as StyleTheme;
    }
    return window.matchMedia('(prefers-color-scheme: dark)').matches
      ? 'dark'
      : 'light';
  }

  themeChange() {
    if (this.storedTheme === 'light') {
      document.documentElement.setAttribute('data-bs-theme', 'dark');
      localStorage.setItem('theme', 'dark');
      this.currentTheme.set('dark');
    } else {
      document.documentElement.setAttribute('data-bs-theme', 'light');
      localStorage.setItem('theme', 'light');
      this.currentTheme.set('light');
    }
    this.storedTheme = localStorage.getItem('theme') as StyleTheme;
  }
}

class SidebarService {
  screenWidth = signal<number>(0);
  isSideNavCollapsed = signal<boolean>(false);
  expandedItem = signal<number>(0);
  menuItems = signal<MenuItem[]>(this.getMenu());

  getMenu(): MenuItem[] {
    const encryptedMenu = localStorage.getItem('menu');
    if (encryptedMenu) {
      const decodedMenu = atob(encryptedMenu);
      const menu = JSON.parse(decodedMenu);
      return menu;
    }
    return [];
  }
}

class DeviceAppService {
  private breakpointObserver = inject(BreakpointObserver);

  private isMobile$ = this.breakpointObserver
    .observe(Breakpoints.Small)
    .pipe(map((resolve) => resolve.matches));

  isMobile = toSignal(this.isMobile$);
}

class AlertService {
  alertasActivas = signal<boolean>(this.getStoredAlert());

  setStoredAlert(value: boolean): void {
    const today = new Date();
    const tomorrow = new Date(today);
    tomorrow.setDate(today.getDate() + 1);
    tomorrow.setHours(0, 0, 0, 0);
    const expirationTime = tomorrow.getTime();
    const data = { value, expirationTime };
    localStorage.setItem('alertasActivas', JSON.stringify(data));
  }

  getStoredAlert(): boolean {
    const storedData = localStorage.getItem('alertasActivas');

    if (storedData) {
      const { value, expirationTime } = JSON.parse(storedData);

      if (expirationTime > new Date().getTime()) {
        return value;
      } else {
        localStorage.removeItem('alertasActivas');
      }
    }

    return true;
  }
}
